$(".nav-toggle").click(function(){
    $(".nav-item").toggleClass("toggle");
});
// scroll down button
$(".arrow").click(function() {
    $('html, body').animate({
        scrollTop: $("#map").offset().top + 2
    }, 1000);
});

// Model for Regions Page
$(".button").click(function(){
    $(".the-modal").css("left", "0%");
});
$(".close-modal").click(function(){
    $(".the-modal").css("left", "110%");
});